
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { validationMixin } from 'vuelidate';
import { required, maxLength, minLength, numeric } from 'vuelidate/lib/validators';
import { accounts } from '@/store/modules/easy-firestore/accounts';

interface Account {
	id: string;
	account_name: string;
	exc_gcp_id_project: string;
	dlk_gcp_id_project: string;
	sct_gcp_id_project: string;
	contact_first_name: string;
	contact_last_name: string;
	contact_email: string;
	note: string;
}

@Component({
	components: { VueJsonPretty },
	mixins: [validationMixin],
	validations: {
		account: {
			id: { required, minLength: minLength(6), maxLength: maxLength(6), numeric },
			account_name: { required },
			exc_gcp_id_project: {},
			dlk_gcp_id_project: {},
			sct_gcp_id_project: {},
			contact_first_name: {},
			contact_last_name: {},
			contact_email: {},
			note: {},
		},
	},
})
export default class AccountsContent extends Vue {
	@State((state) => Object.values(state.accounts.data)) accounts!: Account[];

	search = '';
	showMainDialog = false;
	showDeleteDialog = false;
	isEditing = false;
	account: Account = this.defaultAccount;

	checkId() {
		const errors: string[] = [];
		if (!this.$v.account.id?.$dirty) return errors;
		!this.$v.account.id?.required && errors.push('ID is required.');
		!this.$v.account.id?.minLength && errors.push('ID must be 6 digits long');
		!this.$v.account.id?.maxLength && errors.push('ID must be 6 digits long');
		!this.$v.account.id?.numeric && errors.push('ID must be only numerical');

		if (this.accounts.filter((account) => account.id === this.account.id).length > 0)
			errors.push('ID is already taken');
		return errors;
	}

	getError(key: string) {
		const errors: string[] = [];
		if (!this.$v.account[key]?.$dirty) return errors;
		!this.$v.account[key]?.required && errors.push(`${key} is required.`);
		return errors;
	}

	openMainDialog() {
		this.account = this.defaultAccount;
		this.isEditing = false;
		this.showMainDialog = true;
	}

	openDeleteDialiog(account: Account) {
		this.account = account;
		this.showDeleteDialog = true;
	}

	hideMainDialog() {
		this.showMainDialog = false;
		this.$v.$reset();
	}

	submit() {
		this.$v.$touch();
		if (this.$v.$invalid) return;
		this.$store.dispatch(`${accounts.moduleName}/set`, this.account).then(() => {
			this.hideMainDialog();
		});
	}

	editAccount(account: Account) {
		this.isEditing = true;
		this.account = account;
		this.showMainDialog = true;
	}

	deleteAccount() {
		this.$store
			.dispatch(`${accounts.moduleName}/delete`, this.account.id)
			.then(() => {
				this.showDeleteDialog = false;
			})
			.catch((err) => {
				console.log(err);
				this.showDeleteDialog = false;
			});
	}

	get headers() {
		return [
			{
				text: 'Account ID',
				align: 'left',
				sortable: true,
				value: 'id',
			},
			{
				text: 'Account Name',
				align: 'left',
				sortable: true,
				value: 'account_name',
			},
			{
				text: 'Contact Email',
				align: 'left',
				sortable: true,
				value: 'contact_email',
			},
			{
				text: 'Dlk Project ID',
				align: 'left',
				sortable: true,
				value: 'dlk_gcp_id_project',
			},
			{
				text: 'Updated At',
				align: 'left',
				sortable: true,
				value: 'updated_at',
			},
			{
				text: 'Actions',
				sortable: false,
				value: 'actions',
			},
		];
	}

	get defaultAccount(): Account {
		return {
			id: '',
			account_name: '',
			exc_gcp_id_project: '',
			dlk_gcp_id_project: '',
			sct_gcp_id_project: '',
			contact_first_name: '',
			contact_last_name: '',
			contact_email: '',
			note: '',
		};
	}
}
