var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        { staticClass: "elevation-0", attrs: { color: "transparent" } },
        [
          _c("v-text-field", {
            attrs: {
              "append-icon": "search",
              label: "Search",
              "single-line": "",
              "hide-details": "",
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "mb-2",
              attrs: { color: "primary", dark: "" },
              on: { click: _vm.openMainDialog },
            },
            [_vm._v("New Account")]
          ),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.accounts,
          search: _vm.search,
        },
        scopedSlots: _vm._u([
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-2",
                    attrs: { "x-small": "" },
                    on: {
                      click: function ($event) {
                        return _vm.editAccount(item)
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { "x-small": "" } }, [
                      _vm._v("mdi-pencil"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { "x-small": "" },
                    on: {
                      click: function ($event) {
                        return _vm.openDeleteDialiog(item)
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { "x-small": "" } }, [
                      _vm._v("mdi-delete"),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-dialog",
        {
          attrs: { width: "800px" },
          model: {
            value: _vm.showMainDialog,
            callback: function ($$v) {
              _vm.showMainDialog = $$v
            },
            expression: "showMainDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Create Account")]),
              _c(
                "form",
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "error-messages": _vm.checkId(),
                                  label: "Account Id",
                                  disabled: _vm.isEditing,
                                  counter: 6,
                                  required: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.account.id.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.account.id.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.account.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.account, "id", $$v)
                                  },
                                  expression: "account.id",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "error-messages":
                                    _vm.getError("account_name"),
                                  label: "Account Name",
                                  required: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.account.account_name.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.account.account_name.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.account.account_name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.account, "account_name", $$v)
                                  },
                                  expression: "account.account_name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "GCP Project ID for the Exc project",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.account.exc_gcp_id_project.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.account.exc_gcp_id_project.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.account.exc_gcp_id_project,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.account,
                                      "exc_gcp_id_project",
                                      $$v
                                    )
                                  },
                                  expression: "account.exc_gcp_id_project",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "GCP Project ID for the Dlk project",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.account.dlk_gcp_id_project.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.account.dlk_gcp_id_project.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.account.dlk_gcp_id_project,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.account,
                                      "dlk_gcp_id_project",
                                      $$v
                                    )
                                  },
                                  expression: "account.dlk_gcp_id_project",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label:
                                    "GCP Project ID for the Sanctuary project",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.account.sct_gcp_id_project.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.account.sct_gcp_id_project.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.account.sct_gcp_id_project,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.account,
                                      "sct_gcp_id_project",
                                      $$v
                                    )
                                  },
                                  expression: "account.sct_gcp_id_project",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label:
                                    "First Name of the main account contact",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.account.contact_first_name.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.account.contact_first_name.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.account.contact_first_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.account,
                                      "contact_first_name",
                                      $$v
                                    )
                                  },
                                  expression: "account.contact_first_name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label:
                                    "Last Name of the main account contact",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.account.contact_last_name.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.account.contact_last_name.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.account.contact_last_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.account,
                                      "contact_last_name",
                                      $$v
                                    )
                                  },
                                  expression: "account.contact_last_name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Email of the main account contact",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.account.contact_email.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.account.contact_email.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.account.contact_email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.account, "contact_email", $$v)
                                  },
                                  expression: "account.contact_email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                attrs: { label: "Note" },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.account.note.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.account.note.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.account.note,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.account, "note", $$v)
                                  },
                                  expression: "account.note",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.hideMainDialog } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { text: "" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("Submit")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.showDeleteDialog,
            callback: function ($$v) {
              _vm.showDeleteDialog = $$v
            },
            expression: "showDeleteDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("Warning"),
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Are you sure you want to delete " +
                    _vm._s(_vm.account.account_name) +
                    " (" +
                    _vm._s(_vm.account.id) +
                    ")? "
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDeleteDialog = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: { click: _vm.deleteAccount },
                    },
                    [_vm._v("Delete")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }